<template>
  <i class="three-dots-icon icon">
    <svg width="35" height="23" viewBox="0 0 35 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.5" cy="11.5" r="1.5" fill="#2A3742"></circle>
      <circle cx="17.5" cy="11.5" r="1.5" fill="#2A3742"></circle>
      <circle cx="23.5" cy="11.5" r="1.5" fill="#2A3742"></circle>
    </svg>
  </i>
</template>

<script>
export default {
  name: "ThreeDotsIcon",
};
</script>
