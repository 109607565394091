import axios from "axios";

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_CALC({ state, commit }, data) {
  return await axios.post("https://api2.leasing-trade.ru/graphql", data);
}

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_BRAND_PAGE({ state, commit }, data) {
  await axios
    .get(state.api + "/api/brand/" + data.vehicle + "/" + data.brand)
    .then(({ data }) => {
      commit("brand_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("brand_page", {});
    });
}

export { GET_CALC, GET_BRAND_PAGE };
