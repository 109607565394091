export default {
  home_page(state, data) {
    state.home_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  about_page(state, data) {
    state.about_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  news_page(state, data) {
    state.news_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  press_page(state, data) {
    state.press_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  article_page(state, data) {
    state.article_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  contacts_page(state, data) {
    state.contacts_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  faq_page(state, data) {
    state.faq_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  programs_page(state, data) {
    state.programs_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  option_page(state, data) {
    state.option_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  program_page(state, data) {
    state.program_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  vehicles_page(state, data) {
    state.vehicles_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  vehicle_page(state, data) {
    state.vehicle_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  brand_page(state, data) {
    state.brand_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  product_page(state, data) {
    state.product_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  catalog_page(state, data) {
    state.catalog_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  claim_page(state, data) {
    state.claim_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  landing_page(state, data) {
    state.landing_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  calculator_page(state, data) {
    state.calculator_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  car_page(state, data) {
    state.car_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  navigator_page(state, data) {
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  management_page(state, data) {
    state.management_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  vacancy_list_page(state, data) {
    state.vacancy_list_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
  vacancy_page(state, data) {
    state.vacancy_page = data;
    state.socials = data.socials;
    state.phones = data.phones;
    state.contacts = data.contacts;
    state.navigator = data.navigator;
  },
};
