<template>
  <i class="copyright-icon icon">
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.336 12.048C5.29067 12.048 4.336 11.7973 3.472 11.296C2.61867 10.7947 1.94133 10.112 1.44 9.248C0.949333 8.384 0.704 7.43467 0.704 6.4C0.704 5.36533 0.949333 4.416 1.44 3.552C1.94133 2.688 2.624 2.00533 3.488 1.504C4.352 1.00267 5.312 0.752 6.368 0.752C7.424 0.752 8.37867 0.997333 9.232 1.488C10.096 1.97867 10.7733 2.656 11.264 3.52C11.7547 4.37333 12 5.32267 12 6.368C12 7.41333 11.7493 8.37333 11.248 9.248C10.7467 10.112 10.064 10.7947 9.2 11.296C8.336 11.7973 7.38133 12.048 6.336 12.048ZM6.336 11.168C7.22133 11.168 8.02133 10.9547 8.736 10.528C9.46133 10.1013 10.032 9.52533 10.448 8.8C10.864 8.064 11.072 7.25333 11.072 6.368C11.072 5.48267 10.8693 4.68267 10.464 3.968C10.0587 3.24267 9.49867 2.672 8.784 2.256C8.06933 1.84 7.264 1.632 6.368 1.632C5.472 1.632 4.66133 1.84533 3.936 2.272C3.21067 2.688 2.64 3.25867 2.224 3.984C1.81867 4.70933 1.616 5.51467 1.616 6.4C1.616 7.28533 1.81867 8.09067 2.224 8.816C2.64 9.54133 3.20533 10.1173 3.92 10.544C4.64533 10.96 5.45067 11.168 6.336 11.168ZM6.512 9.664C5.86133 9.664 5.28 9.52533 4.768 9.248C4.256 8.97067 3.856 8.58667 3.568 8.096C3.28 7.59467 3.136 7.02933 3.136 6.4C3.136 5.77067 3.28 5.21067 3.568 4.72C3.856 4.21867 4.256 3.82933 4.768 3.552C5.28 3.27467 5.86133 3.136 6.512 3.136C7.10933 3.136 7.63733 3.264 8.096 3.52C8.56533 3.76533 8.928 4.10133 9.184 4.528L8.016 5.296C7.68533 4.76267 7.17867 4.496 6.496 4.496C5.97333 4.496 5.536 4.672 5.184 5.024C4.84267 5.36533 4.672 5.824 4.672 6.4C4.672 6.976 4.84267 7.44 5.184 7.792C5.536 8.13333 5.97333 8.304 6.496 8.304C7.17867 8.304 7.68533 8.03733 8.016 7.504L9.184 8.24C8.928 8.688 8.56533 9.04 8.096 9.296C7.63733 9.54133 7.10933 9.664 6.512 9.664Z"
        fill="#2A3742"
      ></path>
    </svg>
  </i>
</template>

<script>
export default {
  name: "CopyrightIcon",
};
</script>
