import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/home",
      name: "home",
      component: () => import("./views/home/index.vue"),
    },
    {
      path: "/leasing-land",
      name: "leasing-land",
      component: () => import("./views/leasing-land/index.vue"),
    },
    {
      path: "/about",
      name: "about",
      component: () => import("./views/about/index.vue"),
    },
    {
      path: "/contacts",
      name: "contacts",
      component: () => import("./views/contacts/index.vue"),
    },
    {
      path: "/press",
      name: "press",
      component: () => import("./views/news/index.vue"),
    },
    {
      path: "/press/:url",
      name: "article",
      component: () => import("./views/article/index.vue"),
      meta: {
        parent: [
          {
            title: "Пресс-центр",
            name: "press",
          },
        ],
      },
    },
    {
      path: "/programs",
      name: "programs",
      component: () => import("./views/programs/list.vue"),
    },
    {
      path: "/programs/:url",
      name: "program",
      component: () => import("./views/programs/open.vue"),
      meta: {
        parent: [
          {
            title: "Лизинговые программы",
            name: "programs",
          },
        ],
      },
    },
    {
      path: "/services",
      name: "services",
      component: () => import("./views/vehicles/list.vue"),
    },
    {
      path: "/services/:url",
      name: "service",
      component: () => import("./views/vehicles/open.vue"),
      meta: {
        parent: [
          {
            title: "Услуги",
            name: "services",
          },
        ],
      },
    },
    {
      path: "/services/:vehicle/:brand",
      name: "brand-page",
      component: () => import("./views/brand/open.vue"),
      meta: {
        parent: [
          {
            title: "Услуги",
            name: "services",
          },
        ],
      },
    },
    {
      path: "/services/:vehicle/:brand/:product",
      name: "brand-item",
      component: () => import("./views/brand/product.vue"),
      meta: {
        parent: [
          {
            title: "Услуги",
            name: "services",
          },
        ],
      },
    },
    {
      path: "/options/:url",
      name: "option",
      component: () => import("./views/options/open.vue"),
      meta: {
        parent: [
          {
            title: "Услуги",
            name: "programs",
          },
        ],
      },
    },
    {
      path: "/catalog",
      name: "catalog",
      component: () => import("./views/catalog/list.vue"),
    },
    {
      path: "/catalog/:brand",
      name: "brand",
      component: () => import("./views/catalog/brand.vue"),
      meta: {
        parent: [
          {
            title: "Каталог",
            name: "catalog",
          },
        ],
      },
    },
    {
      path: "/catalog/:brand/:url",
      name: "car",
      component: () => import("./views/catalog/open.vue"),
      meta: {
        parent: [
          {
            title: "Каталог",
            name: "catalog",
          },
        ],
      },
    },
    {
      path: "/request",
      name: "request",
      component: () => import("./views/request/index.vue"),
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("./views/faq/index.vue"),
    },
    {
      path: "/calculator",
      name: "calculator",
      component: () => import("./views/calculator/index.vue"),
    },
    {
      path: "/vacancy",
      name: "vacancy-list",
      component: () => import("./views/vacancy/list.vue"),
    },
    {
      path: "/vacancy/:url",
      name: "vacancy-open",
      meta: {
        parent: [
          {
            title: "Вакансии",
            name: "vacancy-list",
          },
        ],
      },
      component: () => import("./views/vacancy/open.vue"),
    },
    {
      path: "/:link",
      name: "page",
      component: () => import("./views/page/index.vue"),
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
    },
  ];

  routes.forEach((item) => {
    const { meta } = item;
    let { path } = item;
    let baseDir = "/";
    if (meta && meta.project) {
      baseDir = `/${meta.project}`;
    }

    if (path === "/home") {
      path = `${baseDir}/`;
    } else if (path === "/" && item.redirect) {
      item.redirect = `${baseDir}/`;
    } else {
      path = `${path}`;
    }

    item.path = path;
  });

  return new Router({
    mode: "history",
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
      if (to.hash === "") {
        return {
          x: 0,
          y: 0,
          behavior: "smooth",
        };
      }
    },
    fallback: true,
    routes,
  });
}
