<template>
  <i class="arrow-up-icon icon">
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.7625 9.2625L7.5 3.5375L13.2375 9.2625L15 7.5L7.5 -6.55671e-07L-6.55671e-07 7.5L1.7625 9.2625Z"
        fill="#2A3742"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "ArrowUpIcon",
};
</script>
