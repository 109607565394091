<template>
  <div class="city-modal" @submit.prevent>
    <div class="city-modal__header">
      <div class="city-modal__input">
        <input type="text" v-model.trim="cityQuery" placeholder="Поиск города" />
      </div>
    </div>
    <div class="city-modal__body">
      <div class="city-modal__list">
        <a
          href="#"
          @click.prevent="setCity(city)"
          class="city-modal__link"
          :class="{
            'city-modal__link--active': $store.state.geoData && $store.state.geoData.city === city.title_eng,
          }"
          v-for="(city, key) in cities"
          :key="key"
        >
          {{ city.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CityModal",
  props: {
    options: {
      type: Object,
    },
  },
  data() {
    return {
      cityQuery: "",
    };
  },
  computed: {
    cities() {
      return this.$store.state.cities
        .filter((c) => {
          return c.title.toLowerCase().includes(this.cityQuery.toLowerCase());
        })
        .sort((a, b) => a.title.localeCompare(b.title));
    },
  },
  watch: {
    "$route.params"() {
      this.$emit("close");
    },
  },
  methods: {
    setCity(city) {
      if (city) {
        if (!this.$store.state.geoData) {
          this.$store.state.geoData = {};
        }
        this.$store.state.geoData.city = city.title_eng;
        this.$cookie.set("city", city.title_eng);
      } else {
        this.$store.state.geoData = null;
        this.$cookie.delete("city");
      }
      location.reload();
      this.$emit("close");
    },
  },
};
</script>

<style lang="stylus">
@import '~@/styles/global/global.styl';
.city-modal {
  display: grid;

  &__link {
    font-size: 1em;
    font-weight: normal;
    color: main_dark;
    display inline-flex
    width 100%

    &--active {
      font-weight: bold;
      color: main_color;
      text-decoration: underline;
    }
  }

  &__header {
    display flex
    padding: 20px 20px 0;

    +below(768px) {
      padding 5px 5px 0
    }

    & ^[0]__input {
      width 100%
    }
  }

  &__list {
    column-count: 6;
    padding: 30px;

    +below(1170px) {
      column-count: 5;
    }

    +below(980px) {
      column-count: 4;
    }

    +below(768px) {
      column-count: 3;
      padding: 15px;
    }

    +below(640px) {
      column-count: 2;
    }

    +below(480px) {
      column-count: 1;
    }
  }

  &__input {
    display: flex
    flex-direction column
    margin: 10px;
    box-sizing: border-box;
    +below(640px) {
      max-width: 100%;
      width 100%
    }

    input {
      padding 16px 20px
      height 50px
      border-radius radius
      background: main_white;
      border: 1px solid main_gray;
      +below(640px) {
        width 100%
      }

      &:focus {
        border-color main_dark
      }
    }
  }
}

.city-modal__body
  overflow-y auto
  max-height 70vh
</style>
