<template>
  <i class="message-icon icon">
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.92 0.0882309L0.747101 5.55356C-0.220143 5.94206 -0.214547 6.48163 0.56964 6.72225L4.2084 7.85736L12.6274 2.54551C13.0255 2.3033 13.3892 2.4336 13.0903 2.69899L6.26919 8.85498H6.26759L6.26919 8.85578L6.01818 12.6064C6.38589 12.6064 6.54817 12.4378 6.75441 12.2387L8.52182 10.5201L12.1982 13.2356C12.876 13.6089 13.3628 13.417 13.5315 12.608L15.9448 1.23453C16.1918 0.244109 15.5667 -0.20434 14.92 0.0882309Z"
        fill="#2A3742"
      ></path>
    </svg>
  </i>
</template>

<script>
export default {
  name: "MessageIcon",
};
</script>
