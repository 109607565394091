import axios from "axios";

/**
 * Получение данных главной страницы
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_HOME_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/home")
    .then(({ data }) => {
      commit("home_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("home_page", {});
    });
}

/**
 * Получение данных страницы заявки
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_CLAIM_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/claimpage")
    .then(({ data }) => {
      commit("claim_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("claim_page", {});
    });
}
/**
 * Получение данных страницы калькулятора
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_CALCULATOR_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/calculatorpage")
    .then(({ data }) => {
      commit("calculator_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("calculator_page", {});
    });
}
/**
 * Получение данных страницы лендинга
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_LANDING_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/landingpage")
    .then(({ data }) => {
      commit("landing_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("landing_page", {});
    });
}

/**
 * Отправка заявки
 * @param state
 * @param commit
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
async function POST_CLAIM({ state, commit }, data) {
  return await axios.post(state.api + "/api/addclaim", data);
}

/**
 * Поиск
 * @param state
 * @param commit
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
async function POST_SEARCH({ state, commit }, data) {
  return await axios.post(state.api + "/api/postfilter", data);
}

export { GET_LANDING_PAGE, GET_CALCULATOR_PAGE, GET_CLAIM_PAGE, GET_HOME_PAGE, POST_CLAIM, POST_SEARCH };
