import axios from "axios";

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @param q
 * @param type
 * @param page
 * @param models
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_CATALOG_PAGE({ state, commit }, { q, type, page, models } = {}) {
  const params = {};
  if (q) {
    params.q = q;
  }
  if (type) {
    params.type = type;
  }
  if (page) {
    params.page = page;
  }
  if (models) {
    params.models = models;
  }
  await axios
    .get(state.api + "/api/products", {
      params: params,
    })
    .then(({ data }) => {
      commit("catalog_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("catalog_page", {});
    });
}

/**
 * Получение данных страницы машины
 * @param state
 * @param commit
 * @param url
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_CAR_PAGE({ state, commit }, url) {
  await axios
    .get(state.api + "/api/products/" + url)
    .then(({ data }) => {
      commit("car_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("car_page", {});
    });
}

export { GET_CATALOG_PAGE, GET_CAR_PAGE };
