<template>
  <div id="leasing">
    <vue-progress-bar />
    <header-component />
    <Transition name="page" mode="out-in">
      <RouterView :key="$route.fullPath" />
    </Transition>
    <FooterComponent v-if="$route.name === 'home'" />
    <ModalComponent />
    <a href="#" @click.prevent="scrollTop" type="button" class="scroll-top" v-if="showScrollTop">
      <ArrowUpIcon />
    </a>
  </div>
</template>

<script>
import HeaderComponent from "./components/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import ModalComponent from "./components/ModalComponent.vue";
import ArrowUpIcon from "components/svg/ArrowUp.vue";

export default {
  data() {
    return {
      showScrollTop: false,
    };
  },
  created() {
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$Progress.finish();
    });
  },
  mounted() {
    this.$Progress.finish();
    window.onscroll = () => {
      this.showScrollTop = window.scrollY > window.innerHeight;
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
  metaInfo() {
    return this.$seo();
  },
  components: {
    ArrowUpIcon,
    ModalComponent,
    FooterComponent,
    HeaderComponent,
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';

.scroll-top {
  fixed: left 1.5rem bottom 1.5rem;
  border: 1px solid main_color;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: main_color;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  z-index: 10;

  +below(768px) {
    display: none;
  }

  svg path {
    transition: 0.3s;
    fill: main_white;
  }

  &:focus, &:hover {
    border-color: main_dark;
    background: main_dark;

    svg path {
      fill: main_white;
    }
  }
}
</style>
