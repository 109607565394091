import axios from "axios";

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */

async function GET_PRODUCT_PAGE({ state, commit }, url) {
  console.log("product_page");
  await axios
    .get(state.api + "/api/products/" + url)
    .then(({ data }) => {
      commit("product_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("product_page", {});
    });
}

export { GET_PRODUCT_PAGE };
