import Vue from "vue";
import * as dayjs from "dayjs";
import { VueMaskFilter } from "v-mask";

Vue.filter("formatDate", (date) => {
  return dayjs(date).format("DD.MM.YYYY");
});
Vue.filter("humanDate", (date) => {
  return dayjs(date).format("DD MMMM YYYY");
});
Vue.filter("robotDate", (date) => {
  return dayjs(date);
});

Vue.filter("vMask", VueMaskFilter);

Vue.filter("image", (entity, api) => {
  if (entity && entity.img && entity.img.url) {
    return api + entity.img.url;
  }
  return "/public/images/no-image.png";
});

Vue.filter("image_alt", (entity) => {
  if (entity && entity.img && entity.img.alt) {
    return entity.img.alt;
  }
  return "";
});

Vue.filter("image_by_id", (img, state, { width, height, quality = 99 } = {}) => {
  if (img) {
    const urlParams = new URLSearchParams();
    if (width) urlParams.append("width", "" + width);
    if (height) urlParams.append("height", "" + height);
    if (quality) urlParams.append("quality", quality);
    return `${state.api}/action/img/${img.id}?${urlParams.toString()}`;
  }
  return "/public/images/no-image.png";
});
