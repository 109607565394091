<template>
  <nav class="main-menu" :class="{ footer: footer }">
    <div class="main-menu__back" v-if="!footer" @click="$emit('close', $event)"></div>
    <div class="main-menu__content">
      <div v-if="!footer" class="main-menu__mobile">
        <div class="main-menu__mobile__header">
          <a href="#" class="main-menu__mobile__close" @click.prevent="$emit('close', $event)">
            <CloseIcon />
          </a>
        </div>
        <perfect-scrollbar tag="div" class="main-menu__mobile__content">
          <ul class="main-menu__mobile__list">
            <li>
              <b>Лизинг-Трейд</b>
            </li>
            <li v-for="nav in navigatorLeasing" :key="nav.id">
              <router-link :to="{ name: nav.link, hash: nav.hash || '' }" v-if="isVueRoute(nav.link)">{{
                nav.title
              }}</router-link>
              <router-link
                :to="{ name: 'page', params: { link: nav.link } }"
                v-else-if="nav.page && !isValidHttpUrl(nav.link)"
                >{{ nav.title }}</router-link
              >
              <a :href="nav.link" rel="nofollow" v-else-if="isValidHttpUrl(nav.link)">{{ nav.title }}</a>
            </li>
            <li>
              <b>Услуги</b>
            </li>
            <li v-for="nav in navigatorServices" :key="nav.id">
              <router-link :to="{ name: nav.link, hash: nav.hash || '' }" v-if="isVueRoute(nav.link)">{{
                nav.title
              }}</router-link>
              <router-link
                :to="{ name: 'page', params: { link: nav.link } }"
                v-else-if="nav.page && !isValidHttpUrl(nav.link)"
                >{{ nav.title }}</router-link
              >
              <a :href="nav.link" rel="nofollow" v-else-if="isValidHttpUrl(nav.link)">{{ nav.title }}</a>
            </li>
            <li>
              <b>Клиентам</b>
            </li>
            <li>
              <a href="#" @click.prevent="showSearchModal">Поиск</a>
            </li>
            <li v-for="nav in navigatorClients" :key="nav.id">
              <router-link :to="{ name: nav.link, hash: nav.hash || '' }" v-if="isVueRoute(nav.link)">{{
                nav.title
              }}</router-link>
              <router-link
                :to="{ name: 'page', params: { link: nav.link } }"
                v-else-if="nav.page && !isValidHttpUrl(nav.link)"
                >{{ nav.title }}</router-link
              >
              <a :href="nav.link" rel="nofollow" v-else-if="isValidHttpUrl(nav.link)">{{ nav.title }}</a>
            </li>
          </ul>
        </perfect-scrollbar>
        <a :href="'tel:' + phone" class="main-menu__mobile__phone" v-if="phone">
          <PhoneIcon />
          <span>{{ phone }}</span>
        </a>
      </div>
      <div class="container">
        <div class="row">
          <div class="main-menu__item col-3">
            <h3 class="main-menu__item__title">Лизинг-Трейд</h3>
            <ul class="main-menu__list">
              <li v-for="(nav, index) in navigatorLeasing" :key="index">
                <router-link :to="{ name: nav.link, hash: nav.hash || '' }" v-if="isVueRoute(nav.link)">{{
                  nav.title
                }}</router-link>
                <router-link
                  :to="{ name: 'page', params: { link: nav.link } }"
                  v-else-if="nav.page && !isValidHttpUrl(nav.link)"
                  >{{ nav.title }}</router-link
                >
                <a :href="nav.link" rel="nofollow" v-else-if="isValidHttpUrl(nav.link)">{{ nav.title }}</a>
              </li>
            </ul>
          </div>
          <div class="main-menu__item col-3">
            <h3 class="main-menu__item__title">Услуги</h3>
            <ul class="main-menu__list">
              <li v-for="(nav, index) in navigatorServices" :key="index">
                <router-link :to="{ name: nav.link, hash: nav.hash || '' }" v-if="isVueRoute(nav.link)">{{
                  nav.title
                }}</router-link>
                <router-link
                  :to="{ name: 'page', params: { link: nav.link } }"
                  v-else-if="nav.page && !isValidHttpUrl(nav.link)"
                  >{{ nav.title }}</router-link
                >
                <a :href="nav.link" rel="nofollow" v-else-if="isValidHttpUrl(nav.link)">{{ nav.title }}</a>
              </li>
            </ul>
          </div>
          <div class="main-menu__item col-3">
            <h3 class="main-menu__item__title">Клиентам</h3>
            <ul class="main-menu__list">
              <li>
                <a href="#" @click.prevent="showSearchModal">Поиск</a>
              </li>
              <li v-for="(nav, index) in navigatorClients" :key="index">
                <router-link :to="{ name: nav.link, hash: nav.hash || '' }" v-if="isVueRoute(nav.link)">{{
                  nav.title
                }}</router-link>
                <router-link
                  :to="{ name: 'page', params: { link: nav.link } }"
                  v-else-if="nav.page && !isValidHttpUrl(nav.link)"
                  >{{ nav.title }}</router-link
                >
                <a :href="nav.link" rel="nofollow" v-else-if="isValidHttpUrl(nav.link)">{{ nav.title }}</a>
              </li>
            </ul>
          </div>
          <div class="main-menu__item main-menu__item--last col-3">
            <router-link :to="{ name: 'calculator' }" class="btn-dark">Расчитать лизинг</router-link>
            <router-link :to="{ name: 'request' }" class="btn">Отправить заявку</router-link>
            <a :href="'mailto:' + contacts.email" class="main-menu__link" v-if="contacts && contacts.email">
              <EmailIcon />
              <span>{{ contacts.email }}</span>
            </a>
            <router-link :to="{ name: 'home' }" class="main-menu__link" v-if="!footer">
              <CopyrightIcon />
              <span>ООО "Лизинг-Трейд" <br />ИНН 1655096633</span>
            </router-link>
            <ul class="main-menu__socials" v-else>
              <li v-for="(social, index) in socials" :key="index">
                <a
                  :href="social.link"
                  target="_blank"
                  rel="nofollow"
                  v-html="social.icon"
                  :title="social.title"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import CloseIcon from "components/svg/Close.vue";
import PhoneIcon from "components/svg/Phone.vue";
import EmailIcon from "components/svg/Email.vue";
import CopyrightIcon from "components/svg/Copyright.vue";
import Search from "components/modals/Search.vue";
import CityModal from "./modals/City.vue";

export default {
  name: "MainMenuComponent",
  props: {
    footer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    city() {
      if (this.$store.state.geoData) {
        const city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
        if (city) {
          return city.title;
        }
      }
      return "Выберите город";
    },
    navigatorRaw() {
      if (this.$store.state.navigator && this.$store.state.navigator.length) {
        return this.$store.state.navigator;
      }
      return [];
    },
    navigatorLeasing() {
      if (this.$store.state.navigator && this.$store.state.navigator.length) {
        return this.$store.state.navigator
          .filter((n) => n.type && n.type.code === this.$store.state.type.CODE_BOTTOM)
          .filter((n) => n.parent && n.parent.code === this.$store.state.type.CODE_NAVIGATOR_LEASING)
          .filter((n) => n.status && n.status.code === this.$store.state.status.CODE_ACTIVE);
      }
      return [];
    },
    navigatorClients() {
      if (this.$store.state.navigator && this.$store.state.navigator.length) {
        return this.$store.state.navigator
          .filter((n) => n.type && n.type.code === this.$store.state.type.CODE_BOTTOM)
          .filter((n) => n.parent && n.parent.code === this.$store.state.type.CODE_NAVIGATOR_CLIENTS)
          .filter((n) => n.status && n.status.code === this.$store.state.status.CODE_ACTIVE);
      }
      return [];
    },
    navigatorServices() {
      if (this.$store.state.navigator && this.$store.state.navigator.length) {
        return this.$store.state.navigator
          .filter((n) => n.type && n.type.code === this.$store.state.type.CODE_BOTTOM)
          .filter((n) => n.parent && n.parent.code === this.$store.state.type.CODE_NAVIGATOR_SERVICES)
          .filter((n) => n.status && n.status.code === this.$store.state.status.CODE_ACTIVE);
      }
      return [];
    },
    phone() {
      if (this.$store.state.phones && this.$store.state.phones.length) {
        return this.$store.state.phones[0].phone;
      }
      return undefined;
    },
    socials() {
      if (this.$store.state.socials && this.$store.state.socials.length) {
        return this.$store.state.socials;
      }
      return [];
    },
    contacts() {
      if (this.$store.state.contacts && this.$store.state.contacts) {
        return this.$store.state.contacts;
      }
      return undefined;
    },
  },
  methods: {
    showSelectCityModal() {
      this.$store.state._modals.push({
        component: CityModal,
        options: null,
      });
    },
    showSearchModal() {
      this.$store.state._modals.push({
        component: Search,
        options: {
          no_background: true,
          full: true,
          stick_to_top: true,
        },
      });
    },
    isVueRoute(link) {
      const route = this.$router.resolve({
        name: link,
      });
      return !!(route && route.href !== "/");
    },
    /**
     * https://stackoverflow.com/a/43467144/8815956
     * @param string
     * @returns {boolean}
     */
    isValidHttpUrl(string) {
      const regex = new RegExp(/^[a-z]+$/);
      if (!regex.test(string)) {
        return string;
      } else {
        let url;
        try {
          url = new URL(string);
        } catch (_) {
          return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
      }
    },
  },
  components: {
    CopyrightIcon,
    EmailIcon,
    PhoneIcon,
    CloseIcon,
    PerfectScrollbar,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/perfect-scrollbar.styl"

.main-menu {
  width 100%
  height 100%
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &:not(.footer) {
    position absolute
    left 0
    top 0
    transform translateY(100%)
    z-index: 100;
    +below(990px) {
      position fixed
    }

    .main-menu__content {
      +below(990px) {
        position absolute
        height 100%
        padding 0
        width calc(100% - 50px)
      }
    }

    +below(990px) {
      .container {
        display none
      }
    }
  }

  &.footer {
    border-bottom: 1px solid main_gray;

    .main-menu__content {
      box-shadow none
      padding-top 70px

      +below(480px) {
        padding-top 0
      }
    }
  }
  +below(990px) {
    right 0
    transform translateY(0%) !important
    justify-content: flex-end;
  }

  &__socials {
    display flex
    align-items center
    justify-content flex-start
    flex-flow: row wrap;
    list-style none
    margin 10px -3px

    li {
      display flex
      align-items center
      justify-content center
      margin 3px

      a {
        display flex
        align-items center
        justify-content center
        border: 1px solid main_gray;
        box-sizing: border-box;
        border-radius: radius;
        width 50px
        height 50px

        svg {
          width 100%
          height 100%
          max-width 16px
          max-height 16px
          fill main-dark
        }

        &:hover {
          background main_color
          border-color main_color

          svg path{
            fill: main_white
          }
        }
      }
    }
  }

  &__mobile {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    max-height 100%

    +above(991px) {
      display none
    }

    &__phone {
      display flex
      align-items center
      justify-content center
      background main_gray
      font-weight: 500;
      font-size: 20px;
      width 100%
      height 50px

      i {
        margin-right 10px
      }
    }

    &__content {
      flex 1
      width 100%
      display flex
      flex-direction column
      justify-content flex-start
      height inherit
      max-height calc(100% - 100px)
      overflow-y auto
    }

    &__list {
      list-style none
      width 100%
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      flex-shrink 0
      overflow-y auto

      li {
        display flex
        align-items center
        justify-content flex-start
        width 100%

        span
        b
        a {
          width 100%
          padding 12px 30px
          border-bottom 1px solid alpha(main_gray, 50%)
          line-height 1.6em
        }

        a {
          padding-left 40px

          &:hover {
            background main_gray
          }
        }
      }
    }

    &__header {
      display flex
      align-items center
      justify-content flex-end
      width 100%
      border-bottom 1px solid main_gray
      height 50px
    }

    &__close {
      width 50px
      height 50px
      display flex
      align-items center
      justify-content center
    }
  }

  &__list {
    list-style none
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start

    li {
      display inline-flex
      align-items flex-start
      justify-content flex-start
      margin 0 -10px

      a {
        display flex
        padding 4px 10px
      }
    }
  }

  &__item {
    display: flex;
    flex-direction column
    justify-content flex-start
    align-items flex-start

    +below(1200px) {
      &.col-3 {
        grid_item(33.333333%)
      }
    }

    +below(920px) {
      &.col-3 {
        grid_item(100%)
      }
    }
    +below(480px) {
      &.col-3 {
        grid_item(100%)
        margin-top 0
      }
    }

    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }

    &--last {
      margin 15px
      +below(1200px) {
        grid_item(100%)
      }

      .btn
      .btn-dark {
        margin 10px 0
      }
    }
  }

  &__link {
    display flex
    align-items flex-start
    justify-content flex-start
    opacity 0.5
    margin 5px 0

    i {
      margin-right 2px
      width 24px
      height 24px
      display flex
      align-items center
      justify-content center
    }
  }

  &__content {
    background main_white
    box-shadow: 0 15px 30px rgba(main_black, 0.1);
    z-index 2
    position relative
    width 100%
    padding 50px 0
  }

  &__back {
    display: flex;
    width: 100%;
    height: calc(100vh - 105px)
    position: absolute;
    background: main_dark;
    opacity: 0.5;
    z-index 1
    +below(990px) {
      height 100vh
    }
  }
}
</style>
