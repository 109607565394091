import axios from "axios";

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_PROGRAMS_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/programs")
    .then(({ data }) => {
      commit("programs_page", data);
    })
    .catch((err) => {
      console.error(err);
      // console.log(response?.data?.message);
      commit("programs_page", {});
    });
}

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @param url
 * @param id
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_PROGRAM_PAGE({ state, commit }, url) {
  await axios
    .get(state.api + "/api/programs/" + url)
    .then(({ data }) => {
      commit("program_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("program_page", {});
    });
}

export { GET_PROGRAMS_PAGE, GET_PROGRAM_PAGE };
