<template>
  <footer>
    <MainMenuComponent footer />
    <div class="container footer">
      <span>© {{ year }}. ООО «Лизинг-Трейд». ИНН 1655096633. Копирование запрещено.</span>
      <span>Информация, размещенная на данном сайте не является публичной офертой.</span>
      <span
        ><router-link :to="{ name: 'page', params: { link: 'term-conditions' } }" target="_blank"
          >Соглашение</router-link
        >.
        <router-link :to="{ name: 'page', params: { link: 'privacy-policy' } }" target="_blank"
          >Политика конфиденциальности</router-link
        >.
        <a href="https://www.e-disclosure.ru/portal/company.aspx?id=38025" target="_blank" rel="nofollow"
          >Страница раскрытия информации в "Интерфакс"</a
        ></span
      >
    </div>
  </footer>
</template>

<script>
import MainMenuComponent from "components/MainMenu.vue";
import * as dayjs from "dayjs";

export default {
  name: "FooterComponent",
  computed: {
    year() {
      return dayjs().format("YYYY");
    },
  },
  components: { MainMenuComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"

.container.footer {
  display flex
  flex-direction column
  align-items flex-start
  justify-content flex-start
  padding 30px

  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: rgba(main_dark, 0.5);
  }

  a {
    text-decoration underline
    color: rgba(main_dark, 0.5);
  }
}
</style>
