<template>
  <div class="header-container">
    <header class="header">
      <div class="container">
        <router-link :to="{ name: 'home' }" title="Главная страница" class="header-logo">
          <LogoComponent />
        </router-link>
        <nav class="header-nav">
          <ul class="menu">
            <li class="menu__item">
              <template v-for="(nav, index) in navigator">
                <router-link
                  :to="{ name: nav.link, hash: nav.hash || '' }"
                  class="menu__link"
                  :class="{ active: isRouteActive(nav.link) }"
                  :key="index"
                  v-if="isVueRoute(nav.link)"
                  >{{ nav.title }}
                </router-link>
                <router-link
                  :to="{ name: 'page', params: { link: nav.link } }"
                  class="menu__link"
                  :class="{ active: isRouteActive(nav.link) }"
                  :key="index"
                  v-else-if="nav.page && !isValidHttpUrl(nav.link)"
                  >{{ nav.title }}
                </router-link>
                <a
                  :href="nav.link"
                  rel="nofollow"
                  class="menu__link"
                  :key="index"
                  v-else-if="isValidHttpUrl(nav.link)"
                  >{{ nav.title }}</a
                >
              </template>
            </li>
            <li class="menu__item">
              <a
                href="#"
                class="menu__link"
                title="Меню"
                :class="{ active: menuActive }"
                @click.prevent="toggleMenu"
              >
                <ThreeDotsIcon />
                <CloseIcon />
                <MenuIcon />
              </a>
            </li>
          </ul>
        </nav>
        <div class="header-actions">
          <div class="header-actions__row">
            <a href="#" @click.prevent="showSelectCityModal" class="header-actions__city">
              <span class="svgico"><MapIco /></span>
              <span>{{ city }}</span>
            </a>
            <a
              v-if="phone"
              :href="'tel:' + phone"
              class="header-actions__link header-actions__link--bold header__phone"
            >
              <PhoneIcon />
              <span>{{ phone }}</span>
            </a>
          </div>
          <div class="header-actions__row">
            <a href="#" class="header-actions__link" @click.prevent="showLeasingRequestModal">
              <MessageIcon />
              <span>Оставить заявку</span>
            </a>
            <a
              href="https://cabinet.leasing-trade.ru"
              class="header-actions__link"
              target="_blank"
              rel="nofollow"
            >
              <PersonIcon />
              <span>Личный кабинет</span>
            </a>
          </div>
        </div>
        <div class="header-mobile">
          <a
            href="#"
            @click.prevent="phoneActive = !phoneActive"
            class="header-mobile__item"
            :class="{ active: phoneActive }"
            v-if="phone"
            title="Позвонить"
          >
            <PhoneIcon />
          </a>
          <a
            href="#"
            class="header-mobile__item"
            title="Оставить заявку"
            @click.prevent="showLeasingRequestModal"
          >
            <MessageIcon />
          </a>
          <a
            href="https://cabinet.leasing-trade.ru"
            class="header-mobile__item"
            target="_blank"
            rel="nofollow"
            title="Личный кабинет"
          >
            <PersonIcon />
          </a>
          <a href="#" @click.prevent="showSelectCityModal" title="Выбрать город" class="header-mobile__item"
            ><span class="svgico"><MapIco /></span
          ></a>
          <a href="#" class="header-mobile__item" title="Меню" @click.prevent="toggleMenu">
            <MenuIcon />
          </a>
        </div>
      </div>
      <a
        :href="'tel:' + phone"
        v-click-outside="onClickOutsidePhone"
        v-if="phoneActive"
        class="header-mobile-content"
        >{{ phone }}</a
      >
    </header>
    <MainMenuComponent v-if="menuActive" v-click-outside="onClickOutsidePhone" @close="onClickOutsideMenu" />
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import ThreeDotsIcon from "./svg/ThreeDots.vue";
import PhoneIcon from "./svg/Phone.vue";
import MessageIcon from "./svg/Message.vue";
import PersonIcon from "./svg/Person.vue";
import CloseIcon from "./svg/Close.vue";
import MenuIcon from "./svg/Menu.vue";
import MapIco from "./svg/map.vue";
import LeasingRequest from "./modals/LeasingRequest.vue";
import CityModal from "./modals/City.vue";
import MainMenuComponent from "components/MainMenu.vue";
import LogoComponent from "components/svg/Logo.vue";

export default {
  name: "HeaderComponent",
  data() {
    return {
      phoneActive: false,
      menuActive: false,
    };
  },
  computed: {
    city() {
      if (this.$store.state.geoData) {
        const city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
        if (city) {
          return city.title;
        }
      }
      return "Выберите город";
    },
    navigator() {
      if (this.$store.state.navigator && this.$store.state.navigator.length) {
        return this.$store.state.navigator
          .filter((n) => n.type && n.type.code === this.$store.state.type.CODE_TOP)
          .filter((n) => n.status && n.status.code === this.$store.state.status.CODE_ACTIVE);
      }
      return [];
    },
    phone() {
      if (this.$store.state.phones && this.$store.state.phones.length) {
        return this.$store.state.phones[0].phone;
      }
      return undefined;
    },
  },
  watch: {
    "$route.name"() {
      this.menuActive = false;
      this.setNoScroll(false);
    },
    "$route.hash"() {
      this.menuActive = false;
      this.setNoScroll(false);
    },
  },
  methods: {
    showSelectCityModal() {
      this.$store.state._modals.push({
        component: CityModal,
        options: null,
      });
    },
    isRouteActive(route) {
      return !!(
        this.$route.name === route ||
        (this.$route.meta &&
          this.$route.meta.parent &&
          this.$route.meta.parent.filter((p) => p.name === route).length)
      );
    },
    /**
     * https://stackoverflow.com/a/43467144/8815956
     * @param string
     * @returns {boolean}
     */
    isValidHttpUrl(string) {
      const regex = new RegExp(/^[a-z]+$/);
      if (!regex.test(string)) {
        return string;
      } else {
        let url;
        try {
          url = new URL(string);
        } catch (_) {
          return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
      }
    },
    isVueRoute(link) {
      const route = this.$router.resolve({
        name: link,
      });
      return !!(route && route.href !== "/");
    },
    toggleMenu() {
      this.menuActive = !this.menuActive;
      window.scrollTo(0, 0);
      this.setNoScroll(this.menuActive);
    },
    setNoScroll(val) {
      if (val) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
    showLeasingRequestModal() {
      this.$store.state._modals.push({
        component: LeasingRequest,
        options: null,
      });
    },
    onClickOutsidePhone() {
      this.phoneActive = false;
    },
    onClickOutsideMenu() {
      this.menuActive = false;
      this.setNoScroll(false);
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    LogoComponent,
    MainMenuComponent,
    MenuIcon,
    CloseIcon,
    PersonIcon,
    MessageIcon,
    PhoneIcon,
    ThreeDotsIcon,
    MapIco,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
.header-container {
  width 100%
  z-index: 100;
  position relative
}

.header {
  background main_white
  border-bottom 1px solid main_gray
  position relative
  +above(769px) {
    padding-top 21px
    padding-bottom 21px
  }

  .container {
    display flex
    align-items center
    justify-content flex-start
    +below(768px) {
      padding 0
    }
  }
}

.header-mobile {
  +above(769px) {
    display none
  }
  display inline-flex
  align-items center
  margin-left auto
  flex-shrink 0

  &__item {
    padding 22px
    display flex
    align-items center
    justify-content center
    border-left 1px solid main_gray

    +below(400px) {
      padding 0
      width 50px
      height 50px
    }

    &:hover,
    &:focus,
    &.active {
      background main_color

      i svg path {
        fill main_white
      }

      .svgico {
        svg {
          fill main_white
        }
      }
    }

    i {
      max-width 25px
      height 16px
    }
  }
}

.header-mobile-content {
  background main_color
  color main_white
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align center
  padding 18px
  width 100%
  position: absolute;
  box-shadow: 0 15px 15px rgba(main_black, 0.1);
}

.header-logo {
  width 100%
  max-width 180px
  height 60px
  display flex
  justify-content center

  +below(1055px) {
    max-width 150px
  }

  +below(768px) {
    padding 10px 15px 10px 20px
    box-sizing border-box
    width 100%
  }

  +below(400px) {
    height 50px
  }

  img
  svg {
    width 100%
    height 100%
    object-fit contain
    object-position center
  }
}

.header-nav {
  margin-left 50px
  display inline-flex
  flex-shrink: 0;

  +below(1055px) {
    margin-left 15px
  }

  +below(990px) {
    order: 1;
  }
  +below(920px) {
    margin-left 15px
  }
  +below(768px) {
    display none
  }
}

.header-actions {
  flex 1
  display flex
  align-items flex-end
  flex-direction column
  text-align right
  justify-content flex-end

  +below(768px) {
    display none
  }

  &__row {
    display flex
    align-items center
    justify-content flex-end
    grid-gap 15px
  }

  &__city {
    display inline-flex
    gap 5px

    svg {
      fill main_dark !important
    }

    &:hover span:first-child {
      color: rgba(main_dark, 0.5);
    }

    span:first-child {
      color: rgba(main_dark, 0.5);
    }
  }

  &__link {
    display flex
    align-items center
    justify-content flex-end

    i {
      margin-right 10px
    }

    &--bold {
      font-weight: 500;
      font-size: 20px;
      white-space nowrap
      line-height: 24px;
      width auto
    }
  }
}

.menu {
  display flex
  list-style none
  align-items center

  &__item {
    display flex
    align-items center
    +below(990px) {
      &:not(:last-child) {
        display none
      }
    }
  }

  &__link {
    font-weight 600
    font-size 16px
    line-height 20px
    padding 10px 15px
    display flex
    align-items center

    i {
      width 35px
    }

    &.active {
      color main_color
    }

    &:hover i svg {
      path {
        fill main_color
      }

      circle {
        fill main_color
      }

    }
    +above(991px) {
      .menu-icon {
        display none
      }
    }
    +below(990px) {
      i:not(.menu-icon) {
        display none
      }
    }

    &.active .three-dots-icon {
      display none
    }

    &:not(.active) .close-icon {
      display none
    }
  }
}

.svgico
  display inline-flex
  align-items center

  svg
    width 100%
    height 100%
    max-width 16px
    max-height 16px
    fill var(--main_dark)
    transition all 0.3s
</style>
