<template>
  <i class="menu-icon icon">
    <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 1.50463H0V0H25V1.50463ZM25 13.5417H0V12.037H25V13.5417ZM25 7.51111H0V6.01852H25V7.51111Z"
        fill="#2A3742"
      ></path>
    </svg>
  </i>
</template>

<script>
export default {
  name: "MenuIcon",
};
</script>
