import axios from "axios";

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @param url
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_OPTION_PAGE({ state, commit }, url) {
  await axios
    .get(state.api + "/api/options/" + url)
    .then(({ data }) => {
      commit("option_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("option_page", {});
    });
}

export { GET_OPTION_PAGE };
