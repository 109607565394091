<template>
  <form class="search" @submit.prevent>
    <label class="search__label">
      <input class="search__input" type="text" v-model="q" autofocus ref="input" @input="requestSearch" />
    </label>
    <perfect-scrollbar tag="ul" class="search__results">
      <li class="search__result" v-if="loading">
        <LoadingIndicator title="Поиск" />
      </li>
      <li class="search__result" v-else-if="results && !results.length">
        <span class="search__result-title">Ничего не найдено</span>
      </li>
      <template v-else>
        <li v-for="(result, index) in results" :key="index">
          <router-link
            :to="{ name: getRouteName(result), params: { id: result.id, url: result.link } }"
            class="search__result"
          >
            <span class="search__result-category">{{ getCategory(result) }}</span>
            <span class="search__result-title">{{ result.title }}</span>
          </router-link>
        </li>
      </template>
    </perfect-scrollbar>
  </form>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "SearchModal",
  data() {
    return {
      loading: false,
      q: null,
      results: null,
      timeout: null,
    };
  },
  props: {
    options: {
      type: Object,
    },
  },
  watch: {
    "$route.params"() {
      this.$emit("close");
    },
  },
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    getRouteName(item) {
      if (item.status && item.status.table_name) {
        switch (item.status.table_name) {
          case "news":
            return "article";
          case "programs":
            return "program";
          case "options":
            return "option";
          case "products":
            return "car";
        }
      }
    },
    getCategory(item) {
      if (item.status && item.status.table_name) {
        switch (item.status.table_name) {
          case "news":
            return "Новость";
          case "programs":
            return "Программа";
          case "options":
            return "Опция";
          case "products":
            return "Продукт";
          case "vehicles":
            return "Услуга";
        }
      }
      return "";
    },
    requestSearch() {
      if (this.q.length) {
        clearTimeout(this.timeout);
        this.loading = true;
        this.timeout = setTimeout(() => {
          this.search();
        }, 500);
      }
    },
    search() {
      const fd = new FormData();
      fd.append("title", this.q);
      this.$store
        .dispatch("POST_SEARCH", fd)
        .then(({ data }) => {
          this.loading = false;
          if (data && data.join_data) {
            this.results = data.join_data;
          } else {
            this.results = null;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  components: {
    LoadingIndicator,
    PerfectScrollbar,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/perfect-scrollbar.styl"

.search {
  /*min-height 80vh*/
  width (main_width / 3) * 2
  display flex
  flex-direction column
  align-items stretch
  justify-content flex-start
  +below(920px) {
    width 100%
  }

  &__label {
    width 100%
    display flex
    justify-content stretch
  }

  &__input {
    background main_white
    border-radius radius
    padding margin
    width 100%
    outline none
    border none
    font-size 16px
    line-height normal
    transition all 0.3s
    box-shadow 0 0 0 3px rgba(main_color, 0.5)
  }

  &__results {
    margin-top margin
    background main_white
    border-radius radius
    width 100%
    max-height 70vh
    overflow hidden
    list-style none

    > * {
      &:not(:last-child) {
        border-bottom 1px solid rgba(main_dark, 0.3)
      }
    }
  }

  &__result {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    padding margin
    width: 100%;
    cursor pointer

    &:hover {
      .search__result-category {
        color rgba(main_color, 0.8)
      }

      .search__result-title {
        color main_color
      }
    }

    &-category {
      font-size 12px
      line-height normal
      font-weight bold
      color rgba(main_dark, 0.8)
      margin-bottom 20px
      transition .3s
    }

    &-title {
      font-size 16px
      line-height normal
      font-weight normal
      color main_dark
      transition .3s
    }
  }
}
</style>
