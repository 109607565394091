<template>
  <i class="email-icon icon">
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 8.64198V1.23457C14 0.55144 13.4486 0 12.7654 0H1.23457C0.55144 0 0 0.55144 0 1.23457V8.64198C0 9.3251 0.55144 9.87654 1.23457 9.87654H12.7654C13.4486 9.87654 14 9.3251 14 8.64198ZM12.9218 1.14403C13.1934 1.41564 13.0453 1.69547 12.8971 1.83539L9.55556 4.89712L12.7654 8.23868C12.8642 8.35391 12.93 8.53498 12.8148 8.65844C12.7078 8.79012 12.4609 8.78189 12.3539 8.69959L8.7572 5.62963L6.99588 7.23457L5.2428 5.62963L1.64609 8.69959C1.53909 8.78189 1.29218 8.79012 1.18519 8.65844C1.06996 8.53498 1.1358 8.35391 1.23457 8.23868L4.44444 4.89712L1.10288 1.83539C0.954732 1.69547 0.806584 1.41564 1.07819 1.14403C1.34979 0.872428 1.62963 1.00412 1.86008 1.20165L6.99588 5.34979L12.1399 1.20165C12.3704 1.00412 12.6502 0.872428 12.9218 1.14403Z"
        fill="#2A3742"
      ></path>
    </svg>
  </i>
</template>

<script>
export default {
  name: "EmailIcon",
};
</script>
