import axios from "axios";

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @param page
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_NEWS_PAGE({ state, commit }, page = 1) {
  await axios
    .get(state.api + "/api/news?page=" + page)
    .then(({ data }) => {
      commit("news_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("news_page", {});
    });
}

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @param url
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_ARTICLE_PAGE({ state, commit }, url) {
  await axios
    .get(state.api + "/api/article/" + url)
    .then(({ data }) => {
      commit("article_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("article_page", {});
    });
}

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @param page
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_PRESS_PAGE({ state, commit }, page = 1) {
  await axios
    .get(state.api + "/api/press?page=" + page)
    .then(({ data }) => {
      commit("press_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("press_page", {});
    });
}

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @param url
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_PRESS_ARTICLE_PAGE({ state, commit }, url) {
  await axios
    .get(state.api + "/api/press/" + url)
    .then(({ data }) => {
      commit("article_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("article_page", {});
    });
}
export { GET_NEWS_PAGE, GET_ARTICLE_PAGE, GET_PRESS_PAGE, GET_PRESS_ARTICLE_PAGE };
