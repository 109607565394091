<template>
  <div class="lr">
    <span class="lr__title">Заявка на оформление лизинга</span>
    <div class="lr__body">
      <template v-if="notRequested">
        <form class="lr__form" @submit.prevent="submitRequest">
          <div class="lr__form__group" :class="{ error: form.name.message }">
            <label for="name">Ваше имя</label>
            <input
              type="text"
              v-model="form.name.value"
              class="lr__form__input"
              id="name"
              placeholder="Имя"
            />
            <small v-if="form.name.message">{{ form.name.message }}</small>
          </div>
          <div class="lr__form__group" :class="{ error: form.inn.message }">
            <label for="inn">ИНН организации</label>
            <input
              type="text"
              v-model="form.inn.value"
              v-if="form.inn.value && form.inn.value.length <= 12"
              v-mask="'####-#####-#'"
              class="lr__form__input"
              id="inn"
              placeholder="____-_____-_"
            />
            <input
              type="text"
              v-model="form.inn.value"
              v-else
              v-mask="'####-######-##'"
              class="lr__form__input"
              id="inn"
              placeholder="____-_____-_"
            />
            <small v-if="form.inn.message">{{ form.inn.message }}</small>
          </div>
          <div class="lr__form__group" :class="{ error: form.phone.message }">
            <label for="phone">Телефон</label>
            <input
              type="text"
              v-model="form.phone.value"
              class="lr__form__input"
              id="phone"
              v-mask="'# (###) ###-##-##'"
              placeholder="_ (___) ___-__-__"
            />
            <small v-if="form.phone.message">{{ form.phone.message }}</small>
          </div>
          <div class="lr__form__actions">
            <button class="btn-dark" type="submit">
              <LoadingIndicator v-if="loading" title="Подождите" />
              <span v-else>Отправить</span>
            </button>
          </div>
          <div class="lr__form__footer">
            <span
              >Нажимая кнопку «Отправить», я даю свое согласие на обработку моих персональных данных в
              соответствии с
              <router-link :to="{ name: 'page', params: { link: 'privacy-policy' } }" target="_blank"
                >Политикой в отношении обработки персональных данных ООО «Лизинг-Трейд»</router-link
              ></span
            >
          </div>
        </form>
        <div class="lr__picture">
          <img
            loading="lazy"
            decoding="async"
            src="/public/images/leasing-request-image.svg"
            alt="Быстрое принятие решения"
          />
        </div>
      </template>
      <div class="lr__success" v-else>
        <span>Спасибо! Ваша заявка успешно отправлена. <br />Мы свяжемся с Вами в ближайшее время.</span>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "LeasingRequestModal",
  components: { LoadingIndicator },
  data() {
    return {
      loading: false,
      notRequested: true,
      form: {
        name: {
          value: "",
          message: "",
        },
        inn: {
          value: "",
          message: "",
        },
        phone: {
          value: "",
          message: "",
        },
      },
    };
  },
  methods: {
    submitRequest() {
      if (!this.loading) {
        this.loading = true;
        this.resetValidation();
        const fd = new FormData();
        Object.keys(this.form).forEach((key) => {
          fd.append(key, this.form[key].value);
        });
        fd.append("type_id", this.$store.state.type.CODE_LEASING);
        if (![12, 14].includes(this.form.inn.value.length)) {
          this.form.inn.message = "ИНН может состоять только из 10 или 12 цифр";
          this.loading = false;
          return;
        }
        this.$store
          .dispatch("POST_CLAIM", fd)
          .then(({ data }) => {
            this.loading = false;
            if (data.message === "Ok!") {
              if (this.$gtm.enabled()) {
                window.dataLayer?.push({
                  event: "Lead",
                });
              }
              this.notRequested = false;
            } else {
              this.$swal("Заполните все обязательные поля!", "");
            }
          })
          .catch(({ response }) => {
            this.loading = false;
            if (response.status === 422) {
              // Вывод ошибок валидаций от Laravel
              Object.keys(response.data).forEach((message) => {
                Object.keys(this.form).forEach((field) => {
                  if (field === message) {
                    this.form[field].message = response.data[message][0];
                  }
                });
              });
            } else {
              this.$swal("Заполните все обязательные поля!", "");
            }
          });
      }
    },
    /**
     * Сброс сообщений от валидации laravel
     */
    resetValidation() {
      Object.keys(this.form).forEach((field) => {
        this.form[field].message = null;
      });
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"

.lr {
  display flex
  flex-direction column
  align-items center
  justify-content center
  max-width 740px

  &__title {
    font-weight: bold;
    font-size 22px
    line-height: 26px;
    text-transform: uppercase;
    color: main_dark;
    margin 50px 30px 38px
    text-align center
    +below(768px) {
      font-size 18px
      margin 30px
    }
  }

  &__success {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: main_color;
    margin 0 50px 50px;
  }

  &__body {
    display flex
    flex-flow row wrap
    margin -15px
  }

  &__form {
    flex-basis calc(50% - (15px * 2))
    margin 15px
    padding 0 0 50px 50px
    box-sizing border-box
    display flex
    flex-flow row wrap
    +below(768px) {
      flex-basis calc(100% - (15px * 2))
      padding 0 30px 30px
    }

    &__group {
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      width 100%
      margin-bottom 20px

      &.error {
        input {
          border-color error_red
        }

        label {
          color error_red
        }
      }

      label {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: main_dark;
      }

      input {
        background transparent
      }

      small {
        color: error_red;
      }
    }

    &__actions {
      display flex
      flex-flow row wrap
      margin -5px -5px 15px

      & * {
        margin 5px !important
      }
    }

    &__footer {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: rgba(main_dark, 0.5);
    }
  }

  &__picture {
    flex-basis calc(50% - (15px * 2))
    margin 15px
    display flex
    align-items center
    justify-content center
    padding-right 50px
    padding-bottom 50px
    box-sizing border-box
    +below(768px) {
      display none
    }

    img {
      max-width 300px
      width 100%
      height 100%
      object-fit contain
      object-position center
    }
  }
}
</style>
