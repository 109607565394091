import * as global from "./global.js";
import * as home from "./home.js";
import * as about from "./about.js";
import * as news from "./news.js";
import * as contacts from "./contacts.js";
import * as faq from "./faq.js";
import * as programs from "./programs.js";
import * as options from "./options.js";
import * as catalog from "./catalog.js";
import * as navigator from "./navigator.js";
import * as vehicles from "./vehicles.js";
import * as vacancy from "./vacancy.js";
import * as product from "./product.js";

export default Object.assign(
  home,
  global,
  about,
  news,
  contacts,
  faq,
  programs,
  options,
  catalog,
  navigator,
  vehicles,
  vacancy,
  product
);
