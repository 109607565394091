<template>
  <i class="phone-icon icon">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.65074 9.14992C7.63149 10.1448 8.81817 10.913 10.1274 11.4004L11.8987 9.99091C11.9512 9.95475 12.0135 9.93538 12.0772 9.93538C12.141 9.93538 12.2032 9.95475 12.2557 9.99091L15.5445 12.1098C15.6694 12.1849 15.7751 12.288 15.8532 12.4109C15.9314 12.5338 15.9799 12.6732 15.995 12.8181C16.01 12.963 15.9912 13.1095 15.9399 13.2458C15.8886 13.3822 15.8063 13.5048 15.6996 13.6039L14.1585 15.1261C13.9378 15.3442 13.6666 15.5042 13.369 15.5919C13.0714 15.6796 12.7568 15.6923 12.4531 15.6288C9.42374 15.0031 6.63154 13.5379 4.39558 11.4004C2.20924 9.24166 0.696308 6.49487 0.0403042 3.49323C-0.0245005 3.19426 -0.0109749 2.88361 0.0795703 2.59139C0.170115 2.29918 0.33461 2.03531 0.557111 1.82536L2.15452 0.284332C2.25337 0.18275 2.37392 0.104848 2.50714 0.0564571C2.64036 0.00806649 2.78281 -0.00955785 2.92381 0.00490375C3.06481 0.0193654 3.20071 0.0655382 3.32134 0.139965C3.44197 0.214391 3.5442 0.315143 3.62037 0.434676L5.80975 3.68116C5.84751 3.73214 5.8679 3.79391 5.8679 3.85735C5.8679 3.92079 5.84751 3.98255 5.80975 4.03353L4.36739 5.76719C4.86802 7.05025 5.64801 8.20577 6.65074 9.14992Z"
        fill="#2A3742"
      ></path>
    </svg>
  </i>
</template>

<script>
export default {
  name: "PhoneIcon",
};
</script>
