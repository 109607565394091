import axios from "axios";

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_ABOUT_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/about")
    .then(({ data }) => {
      commit("about_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("about_page", {});
    });
}

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @param url
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_MANAGEMENT_PAGE({ state, commit }, url) {
  await axios
    .get(state.api + "/api/management/" + url)
    .then(({ data }) => {
      commit("management_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("management_page", {});
    });
}

export { GET_ABOUT_PAGE, GET_MANAGEMENT_PAGE };
