import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions/index";
import mutations from "./mutations";

Vue.use(Vuex);
export default function createStore() {
  return new Vuex.Store({
    actions: actions,
    mutations: mutations,
    state() {
      return {
        api: process.env.API || "http://localhost:8000",
        _modals: [],
        news_page: {},
        landing_page: {},
        vacancy_page: {},
        brand_page: {},
        alternateLink: undefined,
        cities: [],
        status: {
          CODE_ACTIVE: 1,
          CODE_NOACTIVE: 2,
          CODE_NOT_PUBLISHED: 3,
          CODE_HIDDEN: 4,
        },
        geoData: null,
        type: {
          CODE_YEAR: 1, // Charts
          CODE_SQUARE: 2, // Charts
          CODE_PIE: 3, // Charts
          CODE_COLUMN: 4, // Charts
          CODE_BUBBLE: 5, // Charts
          CODE_LEASING: 1, // Claims
          CODE_CONSULTATION: 2, // Claims
          CODE_DOCUMENT_PROGRAM: 1, // Documents
          CODE_DOCUMENT_INVEST: 2, // Documents
          CODE_PROGRAM_USUAL: 1, // Обычная программа
          CODE_PROGRAM_MAIN: 2, // Программа выводится на главной
          CODE_TOP: 1, // Navigator
          CODE_BOTTOM: 2, // Navigator
          CODE_NAVIGATOR_LEASING: 10, // Navigator
          CODE_NAVIGATOR_SERVICES: 11, // Navigator
          CODE_NAVIGATOR_CLIENTS: 12, // Navigator
          CODE_NAVIGATOR_SYSTEM: 20, // Navigator
          CODE_NAVIGATOR_NOT_SYSTEM: 21, // Navigator
        },
        homeFullPage: undefined,
      };
    },
  });
}
