<template>
  <div class="modal" v-if="$store.state._modals.length">
    <div class="modal__content" v-for="(modal, index) in $store.state._modals" :key="index">
      <div class="modal__background" @click="close(index)"></div>
      <div
        class="modal__body"
        :class="{
          'modal__body--transparent': modal.options && modal.options.no_background,
          'modal__body--stick-to-top': modal.options && modal.options.stick_to_top,
          'modal__body--full': modal.options && modal.options.full,
        }"
      >
        <button class="modal__close" @click="close(index)">
          <CloseIcon />
        </button>
        <component :is="modal.component" @close="close(index)" :options="modal.options"></component>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "./svg/Close.vue";

export default {
  name: "ModalComponent",
  components: { CloseIcon },
  methods: {
    close(i) {
      return this.$store.state._modals.splice(i, 1);
    },
  },
  watch: {
    "$store.state._modals.length"(val) {
      if (val > 0) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
.modal
  fixed left top
  width 100%
  height 100%
  z-index 999
  background: rgba(main_dark, 0.5);

  &__close
    background none
    border none
    padding 0
    cursor pointer
    absolute top right
    transform translate(100%, -100%)
    display flex
    align-items center
    justify-content center
    z-index 99

    +below(768px)
      right 0
      transform translate(0, -100%)
      top -10px

    svg path
      fill main_white

  &__content
    width 100%
    height 100%
    position relative
    display flex
    align-items flex-start
    justify-content center
    padding 64px 0
    overflow-y auto

  &__background
    absolute left top
    width 100%
    height 100%
    z-index 0
    cursor pointer

  &__body
    position relative
    z-index 1
    min-width 300px
    background: main_white;
    box-shadow: 0 30px 60px rgba(main_black, 0.15);
    border-radius: radius;
    margin auto margin

    +below(400px)
      width 80%
      min-width auto

    &--transparent
      background transparent
      box-shadow none

    &--stick-to-top
      margin-top 0

    &--full
      +below(920px) {
        width 100%
      }
</style>
