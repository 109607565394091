import axios from "axios";

/**
 * Получение данных страницы о компании
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_FAQ_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/faq")
    .then(({ data }) => {
      commit("faq_page", data);
    })
    .catch(({ response }) => {
      console.log(response?.data?.message);
      commit("faq_page", {});
    });
}

export { GET_FAQ_PAGE };
