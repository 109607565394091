import Vue from "vue";
import { sync } from "vuex-router-sync";
import * as filters from "./filters.js";

import App from "./App.vue";
import createStore from "./store";
import createRouter from "./router.js";

import Meta from "vue-meta";
import Cookie from "vue-cookie";
import Seo from "./plugins/seo.js";
import VueProgressBar from "vue-progressbar";
import { VueMaskDirective } from "v-mask";
import SocialSharing from "vue-social-sharing";
import vSelect from "vue-select";
import VueSwal from "vue-swal";
import VueJsonLD from "vue-jsonld";
import VueMq from "vue-mq";
import VueTippy from "vue-tippy";
import ClientOnly from "vue-client-only";
import VueGtm from "@gtm-support/vue2-gtm";

Vue.use(VueGtm, {
  id: ["GTM-KHPZTJP", "GTM-TXRJX4H"],
  debug: true,
});

Vue.use(VueProgressBar, {
  color: "#FF8000",
  failedColor: "#FF0000",
  thickness: "4px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
});
Vue.use(VueJsonLD);
Vue.use(Meta);
Vue.use(Cookie);
Vue.use(VueSwal);
Vue.use(Seo);
Vue.use(SocialSharing);
Vue.use(ClientOnly);
Vue.use(VueMq, {
  breakpoints: {
    560: 560,
    768: 768,
    900: 900,
    990: 990,
    1097: 1097,
    lg: Infinity,
  },
  defaultBreakpoint: "lg",
});
Vue.use(VueTippy);

Vue.component("v-select", vSelect);

Vue.directive("mask", VueMaskDirective);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

export default function createApp() {
  const store = createStore();
  const router = createRouter();
  sync(store, router);

  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  });

  return { app, router, store };
}
